import { CLEAR_EXPLANATION, ERROR_ANSWER, ERROR_SUBMIT_ANSWERS, RECEIVE_ANSWER, RECEIVE_EXAM_RESULTS_ANSWER, REQUEST_ANSWER, REQUEST_SUBMIT_ANSWER, } from '../actions/actionTypes';
const initialState = {
    isFetching: false,
    data: {
        correctedAnswers: 0,
        choice_correct: 0,
        answers: [],
    },
    error: null,
};
export function answers(state = initialState, { type, error, payload }) {
    switch (type) {
        case REQUEST_SUBMIT_ANSWER:
        case REQUEST_ANSWER:
            return {
                ...state,
                isFetching: true,
            };
        case ERROR_SUBMIT_ANSWERS:
        case ERROR_ANSWER:
            return {
                ...state,
                isFetching: false,
                error: error,
            };
        case CLEAR_EXPLANATION:
            return {
                ...state,
                data: {
                    ...state.data,
                    explanation: undefined,
                    explanation_img: undefined,
                    choice_correct: 0,
                },
            };
        case RECEIVE_ANSWER:
            return {
                ...state,
                isFetching: false,
                data: {
                    ...payload,
                    answers: [...state.data.answers, payload.choiceId],
                    correctedAnswers: state.data.correctedAnswers +
                        Number(payload.isCorrect),
                },
                error: null,
            };
        case RECEIVE_EXAM_RESULTS_ANSWER:
            return {
                ...state,
                isFetching: false,
                result: payload,
                error: null,
            };
        default:
            return state;
    }
}
