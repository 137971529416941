import { ERROR_QUESTIONS, RECEIVE_QUESTIONS, REQUEST_QUESTIONS, } from '../actions/actionTypes';
const initialState = {
    isFetching: true,
    error: null,
};
export function questions(state = initialState, { type, error, payload }) {
    switch (type) {
        case REQUEST_QUESTIONS:
            return {
                ...state,
                isFetching: true,
            };
        case ERROR_QUESTIONS:
            return {
                ...state,
                isFetching: false,
                error: error,
            };
        case RECEIVE_QUESTIONS:
            return {
                ...state,
                isFetching: false,
                data: payload,
                error: null,
            };
        default:
            return state;
    }
}
