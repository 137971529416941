import { ERROR_EXAM, RECEIVE_EXAM, REQUEST_EXAM } from '../actions/actionTypes';
const initialState = {
    isFetching: false,
    error: null,
};
export function exam(state = initialState, action) {
    switch (action.type) {
        case REQUEST_EXAM:
            return {
                ...state,
                isFetching: true,
            };
        case ERROR_EXAM:
            return {
                ...state,
                isFetching: false,
                error: action.error,
            };
        case RECEIVE_EXAM:
            return {
                ...state,
                isFetching: false,
                data: action.payload,
                error: null,
            };
        default:
            return state;
    }
}
