import * as actionTypes from '../actions/actionTypes';
const initialState = {
    isFetching: false,
    data: {
        auth: false,
        forgotPassword: false,
    },
    error: null,
};
export function auth(state = initialState, action) {
    switch (action.type) {
        case actionTypes.LOGIN_USER_REQUEST:
            return {
                ...state,
                isFetching: true,
            };
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                data: { loggedIn: action.payload.loggedIn },
                isFetching: false,
                error: action.error,
            };
        case actionTypes.FORGOT_PASSWORD_FAILURE:
        case actionTypes.LOGIN_FAILURE:
            return {
                ...state,
                data: {
                    auth: false,
                    forgotPassword: false,
                    loggedIn: false,
                },
                isFetching: false,
                error: action.error,
            };
        case actionTypes.CLEAR_LOGIN_ERROR:
            return {
                ...state,
                error: null,
            };
        case actionTypes.LOGOUT_USER:
            return {
                ...state,
                isFetching: false,
                data: undefined,
                error: null,
            };
        case actionTypes.RECEIVE_USER:
            return {
                ...state,
                data: { ...state.data, ...action.payload.data, loggedIn: true },
                isFetching: false,
                error: action.error,
            };
        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                data: { forgotPassword: true },
                isFetching: false,
                error: action.error,
            };
        default:
            return state;
    }
}
