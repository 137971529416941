//Exam
export const REQUEST_EXAM = 'REQUEST_EXAM';
export const RECEIVE_EXAM = 'RECEIVE_EXAM';
export const ERROR_EXAM = 'ERROR_EXAM';
//Questions
export const REQUEST_QUESTIONS = 'REQUEST_QUESTIONS';
export const RECEIVE_QUESTIONS = 'RECEIVE_QUESTIONS';
export const ERROR_QUESTIONS = 'ERROR_QUESTIONS';
export const SUBMIT_EXAM = 'SUBMIT_EXAM';
//Answer
export const REQUEST_ANSWER = 'REQUEST_ANSWER';
export const RECEIVE_ANSWER = 'RECEIVE_ANSWER';
export const ERROR_ANSWER = 'ERROR_ANSWER';
export const REQUEST_SUBMIT_ANSWER = 'REQUEST_SUBMIT_ANSWER';
export const RECEIVE_EXAM_RESULTS_ANSWER = 'RECEIVE_EXAM_RESULTS_ANSWER';
export const ERROR_SUBMIT_ANSWERS = 'ERROR_SUBMIT_ANSWERS';
export const CLEAR_EXPLANATION = 'CLEAR_EXPLANATION';
//Auth
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RECEIVE_USER = 'RECEIVE_USER';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
