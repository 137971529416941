import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { axiosInstance } from '../utils/axiosInstance';
import { rootReducer } from './reducers';
export default (preloadedState) => {
    const composeEnhancers = (typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
        compose;
    const middlewares = composeEnhancers(applyMiddleware(thunk.withExtraArgument(axiosInstance)));
    const store = createStore(rootReducer, preloadedState, middlewares);
    // this piece of code enables hot reload of state with redux
    if (process.env.NODE_ENV === 'development' && module.hot) {
        module.hot.accept('./reducers', () => {
            store.replaceReducer(require('./reducers').default);
        });
    }
    return store;
};
