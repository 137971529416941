import Cookies from 'universal-cookie';
const cookies = (initialCookies) => {
    const cookies = new Cookies(initialCookies);
    return {
        get: (key) => cookies.get(key),
        set: (key, value, options = {}) => cookies.set(key, value, { path: '/', ...options }),
        remove: (key) => cookies.remove(key, { path: '/' }),
    };
};
export default cookies;
