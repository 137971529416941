import './Header.scss';
import React from 'react';
import { useSelector } from 'react-redux';
export const Header = () => {
    const { data: exam } = useSelector(({ exam }) => exam);
    const imgScr = exam &&
        `${process.env.S3_BUCKET}/${exam === null || exam === void 0 ? void 0 : exam.exam_id}/images/header_img_pc/${exam === null || exam === void 0 ? void 0 : exam.header_img_pc}`;
    return (React.createElement("header", null,
        React.createElement("div", { className: "l-header" },
            React.createElement("h1", null,
                React.createElement("img", { src: imgScr, alt: exam === null || exam === void 0 ? void 0 : exam.metatag_title })))));
};
