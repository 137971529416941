import React from 'react';
import { Layout } from '../../components';
import { Header } from '../../components/Layout/components/Header';
const NotFound = () => {
    return (React.createElement(Layout, { title: "Not Found" },
        React.createElement(Header, null),
        React.createElement("div", { className: "l-container" },
            React.createElement("section", { className: "info-section" },
                React.createElement("div", { className: "summary-sec u-pd-top56" },
                    React.createElement("h1", { className: "summary-heading" }, "\u30DA\u30FC\u30B8\u304C\u898B\u3064\u304B\u308A\u307E\u305B\u3093\u3002"))))));
};
export default NotFound;
