import { useParams } from 'react-router';
export const usePath = () => {
    const { companySlug, programSlug, examSlug, ...params } = useParams();
    const path = `/${companySlug}/${programSlug}/${examSlug}`;
    return {
        path,
        params: {
            companySlug,
            programSlug,
            examSlug,
            ...params,
        },
    };
};
