import React from 'react';
import { Helmet } from 'react-helmet';
export const Meta = ({ title, description, ogTitle, ogImage, ogUrl, ogSiteName, }) => {
    return (React.createElement(Helmet, null,
        React.createElement("title", null, title),
        React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { property: "og:title", content: ogTitle }),
        React.createElement("meta", { name: "og:url", content: ogUrl }),
        React.createElement("meta", { name: "og:image", content: ogImage }),
        React.createElement("meta", { name: "og:site_name", content: ogSiteName }),
        React.createElement("meta", { name: "og:description", content: description }),
        React.createElement("meta", { property: "og:site_name", content: ogTitle }),
        React.createElement("meta", { property: "og:title", content: ogTitle }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:type", content: "website" }),
        React.createElement("meta", { property: "og:url", content: ogUrl }),
        React.createElement("meta", { property: "og:image", content: ogImage }),
        React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
        React.createElement("meta", { name: "twitter:title", content: ogTitle }),
        React.createElement("meta", { name: "twitter:description", content: description }),
        React.createElement("meta", { name: "twitter:image", content: ogImage })));
};
