import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import * as yup from 'yup';
import { Alert, FormGroup } from '..';
import { forgotPassword } from '../../store/actions/auth';
const schema = yup.object().shape({
    email: yup
        .string()
        .email('メールアドレスの形式に誤りがあります。')
        .required('メールアドレスを入力してください。'),
});
export const ForgotPasswordForm = () => {
    const dispatch = useDispatch();
    const { params: { companySlug, programSlug, examSlug }, } = useRouteMatch();
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });
    const { isFetching, error, data } = useSelector(({ auth }) => auth);
    const onSubmit = handleSubmit(async (input) => {
        dispatch(forgotPassword({ ...input, companySlug, programSlug, examSlug }));
    });
    return (React.createElement("form", { className: "form--contents", onSubmit: onSubmit, noValidate: true },
        error && React.createElement(Alert, { variant: "error" }, error.message),
        (data === null || data === void 0 ? void 0 : data.forgotPassword) && (React.createElement(Alert, { variant: "success" }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u30EA\u30BB\u30C3\u30C8\u3059\u308B\u305F\u3081\u306E\u30EA\u30F3\u30AF\u3092\u9001\u4FE1\u3057\u307E\u3057\u305F\u3002\u30E1\u30FC\u30EB\u30DC\u30C3\u30AF\u30B9\u3092\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044\u3002")),
        !(data === null || data === void 0 ? void 0 : data.forgotPassword) && (React.createElement(React.Fragment, null,
            React.createElement(FormGroup, { error: errors.email },
                React.createElement("label", { className: "txt-label", htmlFor: "email" }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
                React.createElement("input", { id: "email", name: "email", ref: register, type: "email", autoComplete: "email", disabled: isFetching, required: true })),
            React.createElement("p", { className: "util-btn" },
                React.createElement("button", { type: "submit", disabled: isFetching }, "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u518D\u8A2D\u5B9A\u3059\u308B")))),
        (data === null || data === void 0 ? void 0 : data.forgotPassword) && (React.createElement("p", { className: "util-btn" },
            React.createElement("a", { href: "login" }, "\u30ED\u30B0\u30A4\u30F3")))));
};
