import { COOKIE_AUTH_TOKEN } from '../../../constants';
import cookies from '../../../helpers/cookies';
import * as actionTypes from '../actionTypes';
function requestLogin() {
    return {
        type: actionTypes.LOGIN_USER_REQUEST,
        error: false,
    };
}
export function errorLogin(error, shouldRemoveToken) {
    if (shouldRemoveToken) {
        cookies().remove(COOKIE_AUTH_TOKEN);
    }
    return {
        type: actionTypes.LOGIN_FAILURE,
        error,
    };
}
function loginSuccess(payload) {
    cookies().set(COOKIE_AUTH_TOKEN, payload.data.data.ambassadorToken, {
        expires: undefined,
    });
    return {
        type: actionTypes.LOGIN_SUCCESS,
        payload: payload.data.data,
        error: false,
    };
}
function receiveUser(payload) {
    return {
        type: actionTypes.RECEIVE_USER,
        payload: payload.data,
        error: null,
    };
}
function forgotPasswordSuccess(payload) {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        payload: payload.data,
        error: null,
    };
}
function forgotPasswordFailure(error) {
    var _a, _b;
    return {
        type: actionTypes.FORGOT_PASSWORD_FAILURE,
        error: (((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error) && {
            message: (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.error,
        }) ||
            error,
    };
}
function resetPasswordSuccess(payload) {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        payload: payload.data,
        error: null,
    };
}
function resetPasswordFailure(error) {
    var _a, _b;
    return {
        type: actionTypes.RESET_PASSWORD_FAILURE,
        error: (((_a = error.response) === null || _a === void 0 ? void 0 : _a.data.error) && {
            message: (_b = error.response) === null || _b === void 0 ? void 0 : _b.data.error,
        }) ||
            error,
    };
}
export const login = ({ companySlug, programSlug, email, password, }) => (dispatch, _, api) => {
    dispatch(requestLogin());
    return api
        .post(`/exams/${companySlug}/${programSlug}/login`, { email, password }, { withCredentials: true })
        .then((response) => dispatch(loginSuccess(response)), (error) => dispatch(errorLogin(error.response.data.error)));
};
export const me = ({ companySlug, programSlug }) => (dispatch, _, api) => {
    dispatch(requestLogin());
    return api
        .get(`/exams/oauth/${companySlug}/${programSlug}`, {
        withCredentials: true,
    })
        .then((response) => dispatch(receiveUser(response)), (error) => dispatch(errorLogin(error.response.data.error, false)));
};
export const forgotPassword = ({ companySlug, programSlug, examSlug, email, }) => (dispatch, _, api) => {
    dispatch(requestLogin());
    return api
        .post(`/exams/${companySlug}/${programSlug}/${examSlug}/forgotPassword`, {
        email,
        language: 'ja',
    })
        .then((response) => dispatch(forgotPasswordSuccess(response)), (error) => dispatch(forgotPasswordFailure(error)));
};
export const resetPassword = ({ companySlug, programSlug, examSlug, password, }) => (dispatch, _, api) => {
    dispatch(requestLogin());
    return api
        .post(`/exams/${companySlug}/${programSlug}/${examSlug}/resetPassword`, {
        password,
    })
        .then((response) => dispatch(resetPasswordSuccess(response)), (error) => dispatch(resetPasswordFailure(error)));
};
export function logout() {
    return {
        type: actionTypes.LOGOUT_USER,
    };
}
