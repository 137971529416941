import './styles/global';
import React from 'react';
import { renderRoutes } from 'react-router-config';
import { AuthProvider } from './contexts/AuthContext';
import { Routes } from './Routes';
import { setLocale } from 'yup';
import * as ja from 'yup-locale-ja';
setLocale(ja.suggestive);
const App = () => {
    return React.createElement(AuthProvider, null, renderRoutes(Routes));
};
export default App;
