import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { Alert, FormGroup } from '..';
import { hashPassword } from '../../helpers/hashPassword';
import { usePath } from '../../helpers/usePath';
import { login } from '../../store/actions/auth';
const schema = yup.object().shape({
    email: yup
        .string()
        .email('メールアドレスの形式に誤りがあります。')
        .required('メールアドレスを入力してください。'),
    password: yup
        .string()
        .required('パスワードを入力してください。')
        .max(255)
        .transform((value, originalValue) => value ? hashPassword(value) : originalValue),
});
export const LoginForm = () => {
    const dispatch = useDispatch();
    const { params } = usePath();
    const { isFetching, error, data } = useSelector(({ auth }) => auth);
    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });
    const onSubmit = handleSubmit(async (input) => {
        dispatch(login({
            ...input,
            ...params,
        }));
    });
    useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.loggedIn) {
            window.location.href = `questions`;
        }
    }, [data]);
    return (React.createElement("form", { className: "form--contents", onSubmit: onSubmit, noValidate: true },
        error && React.createElement(Alert, { variant: "error" }, error.message),
        React.createElement(FormGroup, { error: errors.email },
            React.createElement("label", { className: "txt-label", htmlFor: "email" }, "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9"),
            React.createElement("input", { id: "email", name: "email", type: "email", autoComplete: "email", disabled: isFetching, ref: register, required: true })),
        React.createElement(FormGroup, { error: errors.password },
            React.createElement("label", { className: "txt-label", htmlFor: "password" }, "\u30D1\u30B9\u30EF\u30FC\u30C9"),
            React.createElement("input", { id: "password", name: "password", type: "password", autoComplete: "current-password", disabled: isFetching, ref: register, required: true })),
        React.createElement("p", { className: "form--lead" },
            "\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5FD8\u308C\u305F\u65B9\u306F\u3001",
            React.createElement("a", { href: "forgot-password" }, "\u3053\u3061\u3089"),
            " \u304B\u3089\u518D\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044\u3002"),
        React.createElement("p", { className: "util-btn" },
            React.createElement("button", { type: "submit", disabled: isFetching }, "\u30ED\u30B0\u30A4\u30F3"))));
};
