import './Modal.module.scss';
import { createStyles, Fade, makeStyles, Modal as MaterialModal, } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import React, { useEffect, useState } from 'react';
export const Modal = ({ isOpen, onClose, width = '80%', children, }) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);
    const handleClose = () => {
        setOpen(false);
        onClose && onClose();
    };
    const useStyles = makeStyles((theme) => createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            width,
            paddingTop: '1.5rem',
            paddingBottom: '1.5rem',
            paddingInline: '2rem',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            borderRadius: 8,
            border: '5px solid #eeeeee',
        },
    }));
    const classes = useStyles();
    return (React.createElement(MaterialModal, { "aria-labelledby": "modal-title", "aria-describedby": "modal-description", className: classes.modal, open: open, onClose: handleClose, closeAfterTransition: true, BackdropComponent: Backdrop, BackdropProps: {
            timeout: 500,
        } },
        React.createElement(Fade, { in: open },
            React.createElement("div", { className: classes.paper }, open ? children : null))));
};
