import { loadableReady } from '@loadable/component';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from '../shared/App';
import configureStore from '../shared/store/configureStore';
import ErrorBoundary from '../shared/utils/ErrorBoundary';
const store = configureStore(window.__PRELOADED_STATE__);
// delete window.__PRELOADED_STATE__;
loadableReady(() => hydrate(React.createElement(ErrorBoundary, null,
    React.createElement(CookiesProvider, null,
        React.createElement(Provider, { store: store },
            React.createElement(BrowserRouter, null,
                React.createElement(App, null))))), document.getElementById('react-root')));
if (module.hot && process.env.NODE_ENV === 'development') {
    module.hot.accept();
}
