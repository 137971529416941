import React, { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { matchRoutes } from 'react-router-config';
import { INVALID_CREDENTIALS_CODE, PROGRAM_NOT_FOUND_CODE } from '../constants';
import NotFound from '../pages/NotFound';
import { Routes } from '../Routes';
import { me } from '../store/actions/auth';
export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { error, data: auth } = useSelector(({ auth }) => auth);
    const [{ route, match: { params }, },] = matchRoutes(Routes, history.location.pathname);
    // Run one time when component has rendered(like componentDidMount)
    useEffect(() => {
        if (!route.skipLoginStatus) {
            dispatch(me(params));
        }
    }, []);
    // Run when auth.error value changes. Check after loginStatus has been fetched
    useEffect(() => {
        if (route.key !== 'home' &&
            INVALID_CREDENTIALS_CODE.includes((error === null || error === void 0 ? void 0 : error.code) || '') &&
            !route.skipLoginStatus) {
            history.replace(`/${params.companySlug}/${params.programSlug}/${params.examSlug}/login`);
        }
    }, [error]);
    return (React.createElement(AuthContext.Provider, { value: auth }, (error === null || error === void 0 ? void 0 : error.code) === PROGRAM_NOT_FOUND_CODE ? React.createElement(NotFound, null) : children));
};
