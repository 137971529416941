import { combineReducers } from 'redux';
import { answers } from './answers';
import { auth } from './auth';
import { exam } from './exam';
import { questions } from './questions';
export const rootReducer = combineReducers({
    answers,
    auth,
    exam,
    questions,
});
