import classNames from 'classnames';
import React from 'react';
import { Footer, Meta } from './components';
export const Layout = ({ children, className, ...config }) => {
    const classes = classNames('layout', className);
    return (React.createElement(React.Fragment, null,
        React.createElement(Meta, Object.assign({}, config)),
        React.createElement("main", { className: classes }, children),
        React.createElement(Footer, null)));
};
